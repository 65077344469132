import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`An existing instance of chatbot produt on Engagement Cloud (a super admin should be available on Chatbot & Automation Studio).`}</li>
      <li parentName="ul">{`Manager user, with the same email on both platforms, to login on Contact Center & Analytics Studio.`}</li>
      <li parentName="ul">{`A chatbot skill configured on Contact Center & Analytics Studio.`}</li>
      <li parentName="ul">{`A campaign and contact origin on Chatbot & Automation Studio.`}</li>
    </ul>
    <h2>{`Steps`}</h2>
    <h3>{`Create a chatbot without a group`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`On the bottom left corner of Contact Center and Analytics Studio desktop, click icon `}<em parentName="p">{`App`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down until you find section `}<em parentName="p">{`Contact Center`}</em>{`.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6a2c701d3ae2959adb78d36494a841e4/fccfd/open-chatbot.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.00000000000001%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADEklEQVQoz23R7U9bZRjH8fMn7IUhLM6NOR7nYM5SsxEHeyRKFqNLmCAuLLIlRl1mjE6QDTLnhMLawmgpUAZtHXbQVnvu+8Ap7aFraSmKwDZYLCNE32j8M74GmNFEX3xeXt/kl0vZ0ayyo0XluVZBzjVBbpsktzVEzqcecj/zsvNzL89f9bLrCy8vNPvY3exjT4uPvC+37Wv1UXAzQGGXpMgiUTYju9olOddVcq6H2dmuktseZvdXKntuquR9Ldh7S7DvG0F+h6CgU1BoERRZBMVdgpJuwf7bgpeskgNWiXLalcBsi2KyTlPcOUlJ5yQm+zR5m5EOSX6npNAiKeqSFHdL9t+W28c2SaldUmaXHOyRvNy7SUPxL2xQ6ZihXS7zcXCBS+M/MpTKsrdD/CdSavt3QOPQHY1X+jRMDo1yh4bZqaGc86Yp7dapcSc41m9wYmCGhrH0/0T+Cfx9/Gq/xmHXtiMDGhWDGkqZVae8J0KpdYoXO1QKLCr5lvDWpBKroKxHcvCO5FDfJoHJITD3C0xOlcMDErNLUDEkOfKM4khmOe4yaNMfcXEiw6XgPL2pXzjljjGYWaPGY9A+tUS9P8HVyQVa9J9pDM4yurjO298ZjC1vUDcRx55epWv2MYozlaXSFeWavswHoXmaAhm6EqtU341iT6zyhifGrdhD6v0PaIstcWNmmcZQkpHFNc7ej+FdekpdwKBnboXu9CMUU69OhTOy9bEDdkmJTaXY9sPWvLK+MOX9KhVubWvWa8OSyhGNqhHJ0RGVEx7J0dEwp76VzwiUpvEMxwejvHMvSc2oseW98VmOuXUuhlKcHo3QGEjy5r0oDYE450MPqJ2I8Yk+x1v3I1yZSlMbnOZKJMVlfRbFk87y+l2D4fmn3Ig9pFlfJPT4V8754xjZ3zkfSuKZX+OyNocz8wTX/BNajJ/I/PYn74s4yfU/+EhPEljZwL+yjlI9bGB2THLSHaVqSKfKrXPGF+PkiM67gQTVXp36YJwzYzp1QYOG7w3OTkRoEnFqgxE+1BPUhyNckFEuaNP8BbrTdjVVbTuYAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/6a2c701d3ae2959adb78d36494a841e4/e93cc/open-chatbot.webp 300w", "/static/6a2c701d3ae2959adb78d36494a841e4/b0544/open-chatbot.webp 600w", "/static/6a2c701d3ae2959adb78d36494a841e4/68fc1/open-chatbot.webp 1200w", "/static/6a2c701d3ae2959adb78d36494a841e4/ff260/open-chatbot.webp 1303w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/6a2c701d3ae2959adb78d36494a841e4/eed55/open-chatbot.png 300w", "/static/6a2c701d3ae2959adb78d36494a841e4/7491f/open-chatbot.png 600w", "/static/6a2c701d3ae2959adb78d36494a841e4/8537d/open-chatbot.png 1200w", "/static/6a2c701d3ae2959adb78d36494a841e4/fccfd/open-chatbot.png 1303w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/6a2c701d3ae2959adb78d36494a841e4/8537d/open-chatbot.png",
                  "alt": "open-chatbot",
                  "title": "open-chatbot",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select `}<em parentName="p">{`Chatbot`}</em>{` to open app `}<em parentName="p">{`Chatbot`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A new chatbot can be created inside an existing group or creating a new group or without group.
To create a chatbot without a group, click `}<em parentName="p">{`-Wihtout group-`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down until you find `}<em parentName="p">{`New Chatbot`}</em>{` and click on it.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/684d2/new-chatbot.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "67%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACe0lEQVQ4y32Sy2tTQRSH77/iQkFEEUFEEBH3XapUBBduBLf+DwWxiohIERWtYEnqC1zYGltFkb4NmNSmPjBpkibNzdz3+yafzCQpraAXhnPmd8/55neG0Wa+lBgafcn05yXm8kWWiussFksqzhW+sVBYY3l1Xe2XiiUWC2sqSm1n3qspoeVrJmcffCQKA/7+/DgljCK6abqtdXbkSRxDd3ePtlxuc+HhG5qNOn4QEcUJQShjim7WqW3WME2bMIqVbtkOnh+ovNFsYVr2dk8YRmhf6xbnHuVoCx1dF/hBSJykpEmHTVugmyZp2lGabJQAz/PVXuV+QJKkxHGiNK2waXN+PEdzq0G1Wt8ukJC61aLZ1kmSPjCKEcLAdlxVo7dFL5cHKmCCVmzYDI+/Q5iGsjw4rZN2qFmCphAKNAC2hYHjeKqul+8Epj3gxfEZDEOokQajSVdN02RLurBdpUng4A4lwDAtNX7S75GaVmjYXJl4g96s02obfTcS3CXyarS2ahims+ueBgA5suN6u0delQ6f5mjpLSzL6TnpO9y0dbaEIOmDwjDGNC1cz1c1Qpi4rqdeRBDGhJEENm0uZz/huA626xPGiVoSYPgulufi+BG+aoiVI/kSJNDxfLwghO7gMXbR8tU2V18t8L+v0Q4Jw/Sf/w0vYaVisSECtPnvGww/nmWq+Jup1TJT38pMr1V4K1epTK5UZma9wsz3CrNy/ajw/keFDz9lLDNfqTL2uciliU+M5FbQ8r+qHLrxmj0jz9g7Msm+kQz7r2U4cD3DwdEMh29mOHIry9HbWY7dyXD8boYTYxlO3sty6v4kpx9kGXrynOHJV5zJvuAPIqy2n1IPcBoAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/116d21fa8f9ba36c58e5e6c3aa70b52b/e93cc/new-chatbot.webp 300w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/b0544/new-chatbot.webp 600w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/68fc1/new-chatbot.webp 1200w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/c9c72/new-chatbot.webp 1279w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/116d21fa8f9ba36c58e5e6c3aa70b52b/eed55/new-chatbot.png 300w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/7491f/new-chatbot.png 600w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/8537d/new-chatbot.png 1200w", "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/684d2/new-chatbot.png 1279w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/116d21fa8f9ba36c58e5e6c3aa70b52b/8537d/new-chatbot.png",
                  "alt": "new-chatbot",
                  "title": "new-chatbot",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Chatbot shows:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul"><em parentName="li">{`General Information`}</em>{`: Where we can give it a `}<em parentName="li">{`Name`}</em>{`, associate it with a `}<em parentName="li">{`Campaign`}</em>{` and `}<em parentName="li">{`Contact origin`}</em>{` and `}<em parentName="li">{`Skill`}</em>{` and decide on the chatbot's `}<em parentName="li">{`Language`}</em>{`. `}</li>
      <li parentName="ul"><em parentName="li">{`Script`}</em>{`: Where a flow can be configured and designed.`}</li>
      <li parentName="ul"><em parentName="li">{`Google anlytics`}</em>{`: Where Google analitics integration can be configured.`}</li>
      <li parentName="ul"><em parentName="li">{`Advanced configuration`}</em>{`: Where where we can write functions in code that can be used in the chatbot's functionalities and some additional secutity settings can be managed.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/543136d5edf129de18287cc231a04bb3/1916e/chatbot.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmklEQVQ4y52Tb2ucQBCH9zv3Tb9TCYUWCqUQkuYIhZI279K7izbn6dEc0VX3j666+yuzrhevaaDNwIPu6Dyzu67sw/cI8W6PbV570kDyUHnSWS4tAnmNrHiEcvtK4mu8A0sLgT9Dtgbvr29xcrXEt2gLWXJUJUfJC1Sce6Son9TtSgG2eaj8wDoH58YHvbUQjfFo02EYBgyDDddHnK9xsHYsTPIKbJneQ4katRAQASkltFJolIKSEkopGGMOdF3nme5bYwA4pEUFdrfnsJ2B1hpN03jatvVo3UCHHBVTju5JYq0Ns7Poh8HPMOUVWBKW7GZ7QTOiBvOgMYmmIBk16Pv+sFVe+PO+8LohdCSocHzRHaDxfN8moZ9tMGYkjH/l6E17tIT/jUm4K2uwjIvZV34ZR0I6lPMu0/78Lab8c8KMhPR3+LNH58y6f8AeiUbZKORKguVS4yUxX0TdDFhEHJ9uMrA3VytcrFOc/khwtkxwtkpwvkrweT1ycTuyiEYu4y1Ol3f4eBPjfL3BItrg5HqFV2+/4PW7S/wG+rbtpWGbiOgAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/543136d5edf129de18287cc231a04bb3/e93cc/chatbot.webp 300w", "/static/543136d5edf129de18287cc231a04bb3/b0544/chatbot.webp 600w", "/static/543136d5edf129de18287cc231a04bb3/68fc1/chatbot.webp 1200w", "/static/543136d5edf129de18287cc231a04bb3/420e8/chatbot.webp 1202w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/543136d5edf129de18287cc231a04bb3/eed55/chatbot.png 300w", "/static/543136d5edf129de18287cc231a04bb3/7491f/chatbot.png 600w", "/static/543136d5edf129de18287cc231a04bb3/8537d/chatbot.png 1200w", "/static/543136d5edf129de18287cc231a04bb3/1916e/chatbot.png 1202w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/543136d5edf129de18287cc231a04bb3/8537d/chatbot.png",
                "alt": "chatbot",
                "title": "chatbot",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Fill in `}<em parentName="li">{`Name`}</em>{`, `}<em parentName="li">{`Campaign`}</em>{` and `}<em parentName="li">{`Contact origin`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Next Step`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add Action`}</em>{` and add the actions  needed to the flow.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Next Step`}</em>{`.`}</li>
      <li parentName="ol">{`If needed, configure `}<em parentName="li">{`Google Analytics`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Next Step`}</em>{`.`}</li>
      <li parentName="ol">{`If needed, configure `}<em parentName="li">{`Advanced configurations`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Configurations related to templates, backgorund, colours or conversation reloaded policy are managed on skill level on Contact Center & Analytics Studio or on  Engagement Studio.`}</p>
    </blockquote>
    <h4>{`Result`}</h4>
    <p>{`After publishing and saving, the chatbot can be used on a smartwindow.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For further information about how to add a chatbot into a smartwindow, please check `}<strong parentName="p">{`Campaign Course`}</strong>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      